import React from "react";
import Header from "../../components/home/header";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { open, setStep, clearQuestionnaire, setOriginOptionId } from "../../redux/quiz/actions";
import HeaderQuestionnaire from "../../components/questionnaire/headerQuestionnaire";
import CommandsQuestionnaire from "../../components/questionnaire/commandsQuestionnaire";
import BodyQuestionnaire from "../../components/questionnaire/bodyQuestionnaire";
import useCheckScreen from "../../hooks/useCheckScreen";
import * as S from "./styles";

const Questionnaire = () => {
  const screen = useCheckScreen();
  const isMobile = screen.isMobile;
  const dispatch = useDispatch();
  const location = useLocation();

  const questionnaire = location.state?.questionnaire;
  const stepInitial = location.state?.stepInitial;
  const origin = location.state?.origin;
  const product = location.state?.product;

  if (questionnaire === undefined || !questionnaire) {
    dispatch(clearQuestionnaire());
    window.location.href = process.env.REACT_APP_FRONT;
    return;
  }

  document.body.style.background = "#111B25";

  dispatch(open());

  const getTotalSteps = (questions) => {
    let steps = [];
    if (questions && questions.questions) {
      for (let question of questions.questions) {
        const step = steps.find(
          (item) => item === question.question_step.ordering,
        );
        if (!step) {
          steps.push(question.question_step.ordering);
        }
      }
    }
    return steps.sort();
  };

  const steps = getTotalSteps(questionnaire);
  dispatch(setStep(stepInitial));
  dispatch(setOriginOptionId(null));

  const formatMobileQuestion = () => {
    return (
      <S.BaseContainer justifyContent="center">
        <Header showClose={false}></Header>
        <HeaderQuestionnaire
          title={questionnaire.name}
          stepInitial={stepInitial}
          numberSteps={steps.length}
          numberStepsHeader={steps.length - (stepInitial - 1)}
        ></HeaderQuestionnaire>
        <BodyQuestionnaire origin={origin} product={product}></BodyQuestionnaire>
        <CommandsQuestionnaire
          stepInitial={stepInitial}
        ></CommandsQuestionnaire>
      </S.BaseContainer>
    );
  };

  const formatDesktopQuestion = () => {
    return (
      <S.BaseContainer width="100%">
        <Header showClose={false}></Header>
        <S.BaseContainerDeskTop>
          <S.ContainerImage width="50%"></S.ContainerImage>
          <S.BaseContainer
            width="50%"
            justifyContent="flex-start"
            padding={screen.paddingPageQuestionnaire}
          >
            <HeaderQuestionnaire
              title={questionnaire.name}
              stepInitial={stepInitial}
              numberSteps={steps.length}
              numberStepsHeader={steps.length - (stepInitial - 1)}
            ></HeaderQuestionnaire>
            <BodyQuestionnaire origin={origin} product={product}></BodyQuestionnaire>
            <CommandsQuestionnaire
              stepInitial={stepInitial}
            ></CommandsQuestionnaire>
          </S.BaseContainer>
        </S.BaseContainerDeskTop>
      </S.BaseContainer>
    );
  };

  return (
    <>
      {isMobile && formatMobileQuestion()}
      {!isMobile && formatDesktopQuestion()}
    </>
  );
};

export default Questionnaire;
